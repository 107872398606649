import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import SetDimensions from './tools/services/SetDimensions'

ReactDOM.render(
	<React.StrictMode>
		<SetDimensions />
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 */
reportWebVitals()
